<template>
  <div class="report-component-wrapper" style="height: 100%">
    <v-menu
        v-model="showExpand"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
        class="non-printable"
    >
      <v-list nav dense min-width="140"
              class="non-printable"
      >
        <v-list-item @click="toggleExpand(-1)">
          <v-list-item-title>Усі рівні</v-list-item-title>
        </v-list-item>
        <v-list-item
            v-for="(item, index) in max_lvl+1"
            :key="`index-menu-${index}`"
            @click="toggleExpand(item-1)"
        >
          <v-list-item-title>{{ `Рівень ${item-1}` }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        v-model="setting_dialog"
        temporary
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-tabs fixed-tabs color="grey darken-2">
                <v-tab>Основні</v-tab>
                <v-tab>Групування</v-tab>
                <v-tab-item class="mt-3">
                  <v-row>
                    <v-col cols="12">
                      <AddressElementSelect
                          label="Об’єднана громада" v-model="filters.amalgamated_hromada_id"
                          filled select_type="amalgamated_hromada"
                      />
                    </v-col>
                    <v-col cols="12">
                      <AddressElementSelect
                          label="Населений пункт" v-model="filters.city_id"
                          :parent_id="filters.amalgamated_hromada_id" :use_parent_id="true"
                          filled select_type="city"
                      />
                    </v-col>
                    <v-col cols="12">
                      <AddressElementSelect
                          label="Район міста" v-model="filters.city_area_id"
                          :parent_id="filters.city_id" :use_parent_id="true"
                          filled select_type="city-area"
                      />
                    </v-col>
                    <v-col cols="12" v-if="filters.city_area_id">
                      <AddressElementSelect
                          label="Вулиця" v-model="filters.streets" multiple
                          :parent_id="filters.city_area_id" :use_parent_id="true"
                          filled select_type="street-with-city-area"
                      />
                    </v-col>
                    <v-col cols="12" v-else>
                      <AddressElementSelect
                          label="Вулиця(-ці)" v-model="filters.streets" multiple
                          :parent_id="filters.city_id" :use_parent_id="true"
                          filled select_type="street"
                      />
                    </v-col>
                    <v-col cols="12">
                      <AddressElementSelect
                          label="Будинок(-ки)" v-model="filters.buildings" multiple
                          :parent_id="filters.streets" :use_parent_id="true"
                          filled select_type="building"
                      />
                    </v-col>
                    <v-col cols="12">
                      <Checker :value="filters.checker_id" @autocompleteChange="onCheckerChange"/>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="mt-3">
                  <v-row>
                    <v-col cols="12">
                      <v-card
                          v-for="(item, idx) in grouping"
                          :key="`gr-idx-${idx}`"
                          style="display: flex; align-items: center"
                          class="ma-2 py-0"
                          elevation="3"
                      >
                        <div style="flex: 0 0 60px">
                          <v-checkbox color="success" class="ml-3" v-model="item.on"/>
                        </div>
                        <div style="flex: 1; font-weight: 400; font-size: .96rem">
                          {{ item.text }}
                        </div>
                        <div style="flex: 0 0 80px">
                          <v-btn icon @click="pushUp(item)">
                            <v-icon>mdi-arrow-up</v-icon>
                          </v-btn>
                          <v-btn icon @click="pushDown(item)">
                            <v-icon>mdi-arrow-down</v-icon>
                          </v-btn>
                        </div>
                      </v-card>
                    </v-col>

                  </v-row>
                </v-tab-item>
              </v-tabs>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="page page-a4-landscape-auto" style="height: auto !important; min-height: 215mm"
         :style="`zoom: ${scale / 100}`"
    >
      <div class="report" :style="`transform: scale(${zoom}); transform-origin: top left;`"
           style="height: 100%">
        <table>
          <caption>
            <div class="report-section report-header" style="padding-top: 2px; flex-wrap: wrap;">
              <div class="rp-col-12 header-underline">
                <div class="rp-row rp-text-center" style="font-size: 1.1rem; font-weight: 500">
                  {{ organization.full_name }} (Оборотно-сальдова відомість по будинках/квартирах та послугах)
                </div>
              </div>
              <div class="rp-col-12"></div>
              <div class="rp-col-12 text-center mt-2">
                {{ print_data.organization_details_with_account }}
              </div>
              <div class="rp-col-12 text-left mt-8">
                Дата формування: {{ getCurrentDate() | formatDate }} {{ getPrintDateRange(date_start, date_end) }}
              </div>
            </div>
            <div class="empty-row report-header" style="height: 20px;"></div>
          </caption>
          <thead id="header">
          <tr>
            <template v-if="filters.flat_details">
              <th rowspan="2" style="min-width: 210px; width: 210px !important;">Адреса</th>
              <th rowspan="2" style="min-width: 50px;">Особовий рахунок</th>
              <th rowspan="2" style="min-width: 110px;">Власник</th>
            </template>
            <template v-else>
              <th rowspan="2" style="min-width: 300px; width: 210px !important;">Адреса</th>
            </template>
            <template v-if="filters.balance_detalization">
              <th rowspan="2" style="min-width: 90px; width: 90px">Сальдо на поч. Дт</th>
              <th rowspan="2" style="min-width: 90px; width: 90px">Сальдо на поч. Кт</th>
            </template>
            <template v-else>
              <th rowspan="2" style="min-width: 90px; width: 90px">Сальдо на початок</th>
            </template>
            <th rowspan="2" style="min-width: 90px; width: 90px">Об’єм</th>
            <th rowspan="2" style="min-width: 90px; width: 90px" v-if="filters.flat_details">Тариф</th>
            <th rowspan="2" style="min-width: 90px; width: 90px">Нараховано</th>
            <th rowspan="2" style="min-width: 90px; width: 90px">Перерахунок</th>
            <th rowspan="2" style="min-width: 90px; width: 90px">Пільга</th>
            <th rowspan="2" style="min-width: 90px; width: 90px">Оплачено</th>
            <th rowspan="2" style="min-width: 90px; width: 90px">Монетизація</th>
            <template v-if="filters.balance_detalization">
              <th rowspan="2" style="min-width: 90px; width: 90px">Сальдо на кін. Дт</th>
              <th rowspan="2" style="min-width: 90px; width: 90px">Сальдо на кін. Кт</th>
            </template>
            <template v-else>
              <th rowspan="2" style="min-width: 90px; width: 90px">Сальдо на кінець</th>
            </template>
          </tr>
          </thead>

          <tbody v-for="(item, idx) in items"
                 :key="item.index"
                 :class="`${item.items ? item.class + ' details' : item.class}`"
                 :style="`${item.items ? 'display: none;' : ''}`"
                 :data-level="`level-${item.level}`"
                 :data-button="`button-${idx}`"
          >
          <template v-if="!item.items && by_grouping">
            <tr :class="`bg-level-${item.level}`">
              <td class="grow"
                  :style="`padding-left: ${item.level === 0 ? 8 : item.level * 16}px`"
              >
                <v-icon size="14" class="mr-1" color="grey darken-3"
                        @click.stop="showRows(item.class, `button-${idx}`)"
                        :id="`button-${idx}`"
                >
                  {{ getCollapseIconNew(item.level) }}
                </v-icon>
                {{ item.group_value }}
              </td>
              <template v-if="filters.balance_detalization">
                <td style="width: 90px; min-width: 90px">{{ item.start_balance_plus | formatNumber }}</td>
                <td style="width: 90px; min-width: 90px">{{ item.start_balance_minus | formatNumber }}</td>
              </template>
              <template v-else>
                <td style="width: 90px; min-width: 90px">{{ item.start_balance | formatNumber }}</td>
              </template>
              <td style="width: 90px; min-width: 90px">{{ item.square | formatNumber }}</td>
              <td style="width: 90px; min-width: 90px" v-if="filters.flat_details"></td>
              <td style="width: 90px; min-width: 90px">{{ item.charge | formatNumber }}</td>
              <td style="width: 90px; min-width: 90px">{{ item.recalculation | formatNumber }}</td>
              <td style="width: 90px; min-width: 90px">{{ item.privilege | formatNumber }}</td>
              <td style="width: 90px; min-width: 90px">{{ item.pay | formatNumber }}</td>
              <td style="width: 90px; min-width: 90px">{{ (item.monetary_privilege + item.monetary_subsidy) | formatNumber }}</td>
              <template v-if="filters.balance_detalization">
                <td style="width: 90px; min-width: 90px">{{ item.end_balance_plus | formatNumber }}</td>
                <td style="width: 90px; min-width: 90px">{{ item.end_balance_minus | formatNumber }}</td>
              </template>
              <template v-else>
                <td style="width: 90px; min-width: 90px">{{ item.end_balance | formatNumber }}</td>
              </template>
            </tr>
          </template>
          <template v-else-if="!item.items && !by_grouping">
            <tr>
              <td style="min-width: 300px; width: 210px !important;">{{ item.street_name }}</td>
              <template v-if="filters.balance_detalization">
                <td style="min-width: 90px; width: 90px">{{ item.start_balance_plus | formatNumber }}</td>
                <td style="min-width: 90px; width: 90px">{{ item.start_balance_minus | formatNumber }}</td>
              </template>
              <template v-else>
                <td style="min-width: 90px; width: 90px">{{ item.start_balance | formatNumber }}</td>
              </template>
              <td style="width: 90px; min-width: 90px">{{ item.square | formatNumber }}</td>
              <td style="width: 90px; min-width: 90px" v-if="filters.flat_details">{{ item.tariff | formatNumber }}</td>
              <td style="min-width: 90px; width: 90px">{{ item.charge | formatNumber }}</td>
              <td style="min-width: 90px; width: 90px">{{ item.recalculation | formatNumber }}</td>
              <td style="min-width: 90px; width: 90px">{{ item.privilege | formatNumber }}</td>
              <td style="min-width: 90px; width: 90px">{{ item.pay | formatNumber }}</td>
              <td style="min-width: 90px; width: 90px">{{ (item.monetary_privilege + item.monetary_subsidy) | formatNumber }}</td>
              <template v-if="filters.balance_detalization">
                <td style="min-width: 90px; width: 90px">{{ item.end_balance_plus | formatNumber }}</td>
                <td style="min-width: 90px; width: 90px">{{ item.end_balance_minus | formatNumber }}</td>
              </template>
              <template v-else>
                <td style="min-width: 90px; width: 90px">{{ item.end_balance | formatNumber }}</td>
              </template>
            </tr>
          </template>
          <template v-else>
            <tr
                v-for="subitem in item.items"
                :key="subitem.index"
            >
              <td
                  style="min-width: 300px;"
                  :style="`padding-left: ${(item.level + 2) * 16}px`"
              >{{ subitem.street_name }}</td>

              <template v-if="filters.balance_detalization">
                <td style="min-width: 90px; width: 90px">{{ subitem.start_balance_plus | formatNumber }}</td>
                <td style="min-width: 90px; width: 90px">{{ subitem.start_balance_minus | formatNumber }}</td>
              </template>
              <template v-else>
                <td style="min-width: 90px; width: 90px">{{ subitem.start_balance | formatNumber }}</td>
              </template>
              <td style="width: 90px; min-width: 90px">{{ subitem.square | formatNumber }}</td>
              <template v-if="filters.flat_details">
                <td style="width: 90px; min-width: 90px" v-if="filters.flat_details">{{ subitem.tariff | formatNumber }}</td>
              </template>
              <td style="min-width: 90px; width: 90px">{{ subitem.charge | formatNumber }}</td>
              <td style="min-width: 90px; width: 90px">{{ subitem.recalculation | formatNumber }}</td>
              <td style="min-width: 90px; width: 90px">{{ subitem.privilege | formatNumber }}</td>
              <td style="min-width: 90px; width: 90px">{{ subitem.pay | formatNumber }}</td>
              <td style="min-width: 90px; width: 90px">{{ (subitem.monetary_privilege + subitem.monetary_subsidy) | formatNumber }}</td>
              <template v-if="filters.balance_detalization">
                <td style="min-width: 90px; width: 90px">{{ subitem.end_balance_plus | formatNumber }}</td>
                <td style="min-width: 90px; width: 90px">{{ subitem.end_balance_minus | formatNumber }}</td>
              </template>
              <template v-else>
                <td style="min-width: 90px; width: 90px">{{ subitem.end_balance | formatNumber }}</td>
              </template>
            </tr>
          </template>
          </tbody>
          <tr class="bg-level-0" style="text-align: center">
            <td class="grow" style="font-weight: 500; text-align: right;">Всього: </td>
            <template v-if="filters.balance_detalization">
              <td style="width: 90px; min-width: 90px">{{ getTotal('start_balance_plus') | formatNumber }}</td>
              <td style="width: 90px; min-width: 90px">{{ getTotal('start_balance_minus') | formatNumber }}</td>
            </template>
            <template v-else>
              <td style="width: 90px; min-width: 90px">{{ getTotal('start_balance') | formatNumber }}</td>
            </template>
            <td style="width: 90px; min-width: 90px">{{ getTotal('square') | formatNumber }}</td>
            <td style="width: 90px; min-width: 90px" v-if="filters.flat_details"></td>
            <td style="width: 90px; min-width: 90px">{{ getTotal('charge') | formatNumber }}</td>
            <td style="width: 90px; min-width: 90px">{{ getTotal('recalculation') | formatNumber }}</td>
            <td style="width: 90px; min-width: 90px">{{ getTotal('privilege') | formatNumber }}</td>
            <td style="width: 90px; min-width: 90px">{{ getTotal('pay') | formatNumber }}</td>
            <td style="width: 90px; min-width: 90px">{{ (getTotal('monetary_privilege') + getTotal('monetary_subsidy')) | formatNumber }}</td>
            <template v-if="filters.balance_detalization">
              <td style="width: 90px; min-width: 90px">{{ getTotal('end_balance_plus') | formatNumber }}</td>
              <td style="width: 90px; min-width: 90px">{{ getTotal('end_balance_minus') | formatNumber }}</td>
            </template>
            <template v-else>
              <td style="width: 90px; min-width: 90px">{{ getTotal('end_balance') | formatNumber }}</td>
            </template>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import reportAPI from "@/utils/axios/reports"
import {ALERT_SHOW} from "@/store/actions/alert";
import {LANDSCAPE_ZOOM_WIDTH} from "@/utils/reports";
import ReportHouseAccountingComponentMixin from "@/mixins/report_house_accounting";


export default {
  name: "new_general_report_with_grouping",
  mixins: [ReportHouseAccountingComponentMixin],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    Checker: () => import('@/components/autocomplite/Checker'),
  },
  data() {
    return {
      filters: {
        checker_id: null,
        amalgamated_hromada_id: null,
        city_id: null,
        city_area_id: null,
        streets: [],
        buildings: [],
        services: [],
        balance_detalization: false
      },
      zoom_width: LANDSCAPE_ZOOM_WIDTH,
      grouping: [
        { text: 'Послуги', value: 'service_id', on: true },
        { text: 'Об’єднані громади', value: 'amalgamated_hromada_id', on: true },
        { text: 'Населений пункт', value: 'city_id', on: true },
        { text: 'Район міста', value: 'city_area_id', on: true },
      ],
    }
  },
  methods: {
    generate_xlsx_file() {
      if (this.date_start && this.date_end) {
        this.$emit('xlsx_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: Object.assign({}, this.filters, {grouping: grouping})
        }
        reportAPI.general_report_by_flat_by_service_xlsx(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = `general_report_by_flat_by_service_${this.formatDate(this.date_start, 'MM_YYYY')}_${this.formatDate(this.date_end, 'MM_YYYY')}.xlsx`
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
              this.$emit('xlsx_loading_status', false)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('xlsx_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_xlsx')
            })
      }
    },
    generate_email_send() {
      if (this.date_start && this.date_end) {
        this.$emit('email_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          email: this.email_address,
          filters: Object.assign({}, this.filters, {grouping: grouping})
        }
        reportAPI.general_report_by_flat_by_service_email(payload)
            .then(response => response.data)
            .then(data => {
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {color: data.status === 'OK' ? 'success' : 'error', text: data.text})
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_email')
            })
      }
    },
    generate_report() {
      if (this.date_start && this.date_end) {
        this.$emit('report_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: Object.assign({}, this.filters, {grouping: grouping})
        }
        reportAPI.new_general_report_with_grouping(payload)
            .then(response => response.data)
            .then(data => {
              this.items = []
              this.expand = false
              this.by_grouping = data.by_grouping
              this.max_lvl = data.max_level
              this.items = data.rows
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_report', !!this.items)
              this.$emit('report_loading_status', false)
              this.$nextTick(() => {
                this.expand = false
                this.toggleExpand(this.max_lvl)
                this.getOnFullPage()
              })
            })
      } else {
        this.$store.commit(ALERT_SHOW, {text: 'Місяць - має бути заповнений', color: 'error'})
      }
    },
    getTotal(col) {
      let total = 0

      if (this.by_grouping) {
        total = this.items.filter(item => item.level === 0 && !item.items).reduce((acc, r) => {
          return acc + r[col]
        }, 0)
      } else {
        total = this.items.filter(item => !item.level).reduce((acc, r) => {
          return acc + r[col]
        }, 0)
      }
      return total
    },
    getOnFullPage() {
      let width = 12
      const first_row = document.getElementById('header')
      first_row.childNodes.forEach(item => width += (item.clientWidth || 0))
      const gen_element = document.getElementsByClassName('report')[0]
      const scrollWidth = width * this.zoom
      const client_width = gen_element.clientWidth * this.zoom
      let zoom_width = 0
      let zoom = client_width / scrollWidth
      if (zoom === 0) zoom = 1
      if (zoom > 1) {
        zoom = 1
        zoom_width = LANDSCAPE_ZOOM_WIDTH
      }

      if (zoom !== 1) {
        zoom_width = 1 / zoom * LANDSCAPE_ZOOM_WIDTH
      }

      this.zoom = zoom
      this.zoom_width = zoom_width
    },
  },
  created() {
    reportAPI.report_settings("general_report_by_flat_by_service")
        .then(response => response.data)
        .then(data => {
          const server_filters = JSON.parse(data)
          Object.keys(this.filters).forEach(key => {
            if (server_filters[key] !== undefined) {
              this.filters[key] = server_filters[key]
            }
          })
          if (server_filters['grouping'] && this.grouping) {
            this.grouping.forEach(g => {
              g.on = !!server_filters.grouping.includes(g.value);
            })
          }
        })
        .finally(() => {
          this.$nextTick(() => this.getOnFullPage())
        })
  }
}
</script>

<style lang="scss" scoped>

</style>